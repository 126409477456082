<template>
  <Header/>
  <h1>{{ currentRoute.params.consultantName }} ({{ currentRoute.params.consultantId }})</h1>
  <Footer/>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'PageConsultant',
  components: {
    Header,
    Footer
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
}
</script>
